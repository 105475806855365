import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingScreen from "./components/landing-screen";
import PostureScreen from "./components/posture-screen";
import Layout from "./components/layout";
import SpcaeScreen from "./components/spcae-screen";
import VelocitasScreen from "./components/velocitas";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<LandingScreen />} />
          <Route
            path="/posture-datenschutzerklärung"
            element={<PostureScreen />}
          />
          <Route
            path="/spcae-datenschutzerklaerung"
            element={<SpcaeScreen />}
          />
          <Route path="/velocitas" element={<VelocitasScreen />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;