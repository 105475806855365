import React from "react";

const VelocitasScreen = () => {
  return (
    <div style={{ padding: "1rem" }}>
      I don't collect any personal data for Velocitas.
    </div>
  );
};

export default VelocitasScreen;
