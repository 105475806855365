import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import postureImage from "../resources/icon_1024x1024_posture.png";
import spcaeImage from "../resources/icon_1024x1024_spcae.png";

const LandingScreen: React.FC = () => {
  const applyMediaQuery = () => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    return mediaQuery.matches;
  };
  const [isMobile, setIsMobile] = React.useState(applyMediaQuery());

  const containerStyle: React.CSSProperties = {
    background: "linear-gradient(to bottom, #333333, #000000)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
    minHeight: "1200px",
    height: "90vh",
  };

  const footerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "1rem",
    minHeight: "100px",
    height: "10vh",
    textAlign: "right",
  };

  const imagesContainerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: "3rem",
    ...(isMobile ? { flexDirection: "column" } : { flexDirection: "row" }),
  };

  const imgStyle: React.CSSProperties = {
    width: "300px",
    height: "300px",
    objectFit: "cover",
    borderRadius: "10%",
  };

  const titleStyle: React.CSSProperties = {
    marginTop: "1rem",
    marginBottom: "0.5rem",
    fontWeight: "bold",
    color: "white",
    fontSize: "2rem",
  };

  const linkStyle: React.CSSProperties = {
    color: "rgba(255, 255, 255, 0.5)",
    textDecoration: "none",
  };

  useEffect(() => {
    const updateLayout = () => {
      setIsMobile(applyMediaQuery());
    };
    window.addEventListener("resize", updateLayout);
    return () => {
      window.removeEventListener("resize", updateLayout);
    };
  }, []);

  return (
    <div style={containerStyle}>
      <div style={imagesContainerStyle}>

        <div>
          <a href="https://apps.apple.com/de/app/spcae/id1630357706">
            <img src={spcaeImage} alt="Image 1" style={imgStyle} />
          </a>
         
          <p style={titleStyle}>{"Spcae"}</p>
          <Link style={linkStyle} to="/spcae-datenschutzerklaerung">
            Datenschutzerklärung
          </Link>
        </div>

        <div>
          <a href="https://apps.apple.com/de/app/posture/id1564400135">
            <img src={postureImage} alt="Image 2" style={imgStyle} />
          </a>

          <p style={titleStyle}>{"Posture"}</p>
          <Link style={linkStyle} to="/posture-datenschutzerklärung">
            Datenschutzerklärung
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingScreen;
