import React from "react";

const footerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  margin: "1rem",
  //   minHeight: "200px",
  alignItems: "stretch",
  //   height: "10vh",
  textAlign: "right",
};

const Footer = () => {
  return (
    <footer>
      <div style={footerStyle}>
        <div>© 2023 Maximilian Graf</div>
        <div>
          Impressum
          <br />
          Maximilian Graf
          <br />
          Hohenzollernstraße 2A
          <br />
          80801 München
          <br />
          Deutschland
          <br />
          015141229309
          <br />
          info@maximiliangraf.eu
          <br />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
